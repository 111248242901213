<script setup lang="ts">
import { retry } from '@clovyr/pollen/util/retry';

import { config } from '@/init/config';
import { useEventBus } from '@/stores/event_bus';

import Modal from '../elements/Modal.vue';

const { eventBus } = useEventBus();

const emit = defineEmits(['close']);
const showModal = ref(false);

let widgetID = '';

onMounted(async () => {
  try {
    await retry(
      async () => {
        // @ts-expect-error global from cloudflare script
        if (typeof turnstile === 'undefined') {
          throw new Error('Turnstile not loaded');
        }
      },
      10,
      true,
    );
  } catch {
    // bail out
    // TODO: emit event to continue without captcha??
    eventBus.emit('captcha:complete', { success: false });
    emit('close');
    return;
  }

  // @ts-expect-error global from cloudflare script
  widgetID = turnstile.render('.captcha', {
    sitekey: config.VITE_TURNSTILE_SITE_KEY,
    appearance: 'interaction-only',
    'before-interactive-callback': async () => {
      showModal.value = true;
    },
    async callback(token) {
      eventBus.emit('captcha:complete', { success: true, token });
      emit('close');
    },
  });
});

onUnmounted(() => {
  if (widgetID) {
    // @ts-expect-error global from cloudflare script
    turnstile.remove(widgetID);
  }
});

function onClose() {
  eventBus.emit('captcha:complete', { success: false });
  emit('close');
}
</script>

<template>
  <Modal :show="showModal" theme="transparent" size="narrow" @close="onClose" max-on-mobile>
    <template #body>
      <h2>Please Verify</h2>
      <br />
      <p>In order to launch, you must verify that you are a human</p>
      <br />
      <div class="captcha"></div>
    </template>
  </Modal>
</template>
