<script setup lang="ts">
import InlineSvg from 'vue-inline-svg';
import { email, required } from '@vee-validate/rules';
import { defineRule, Field } from 'vee-validate';

import { ImageMedia } from '@clovyr/bed';
import UploadIcon from '@clovyr/bed/assets/images/uploadIcon.svg';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import { ButtonSize } from '@clovyr/bed/components/controls/types';
import { FileAPI } from '@clovyr/pollen/file';

import { useDrag } from '@/composables/useDrag';

import FieldInfoIcon from '../icons/FieldInfoIcon.vue';

// register vee-validate rules
// see https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules

defineRule('email', email);
defineRule('required', required);

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    default: '',
  },
  shape: {
    type: String,
    default: 'oval', // square, rounded, cubed
  },
  modelValue: {
    type: String,
    default: '',
  },
  reveal: {
    type: Boolean,
    default: false,
  },
  passwordValidate: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  subtext: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: ButtonSize.Medium,
  },
  tooltip: {
    type: String,
    default: '',
  },
  focus: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  fileUpload: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: String,
    default: '',
  },
  validateOnInput: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const input = ref<HTMLInputElement | null>(null);
const uploadInput = ref<HTMLInputElement | null>(null);

const { name, label, placeholder, modelValue } = toRefs(props);

async function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    const f = await new FileAPI().upload(file);
    emit('update:modelValue', f.fileurl);
  }
}

async function onDropFile(file: File) {
  if (file.type.indexOf('image') >= 0) {
    const f = await new FileAPI().upload(file);
    emit('update:modelValue', f.fileurl);
  }
}

async function onClickUpload() {
  if (uploadInput.value) {
    uploadInput.value.showPicker();
  }
}

const { isDragging, onDragOver, onDragEnter, onDragLeave, onDrop } = useDrag(
  props.fileUpload,
  onDropFile,
);

onMounted(() => {
  if (props.focus && input.value) {
    input.value.focus();
  }
});

const updateColor = (event) => {
  emit('update:modelValue', event.target.value);
};

const CSSClasses = computed(() => {
  return [
    props.shape && `textinput-block--${props.shape}`,
    props.type && `textinput-block--${props.type}`,
    props.reveal && `textinput-block--reveal`,
    props.passwordValidate && `textinput-block--password-validate`,
    props.size && `textinput-block--${props.size}`,
  ];
});
</script>

<template>
  <Field
    type="text"
    :name="name"
    :rules="rules"
    v-slot="{ field, errorMessage }"
    :model-value="modelValue"
    :validateOnInput="validateOnInput"
    @update:model-value="(v) => $emit('update:modelValue', v)"
  >
    <div
      class="textinput-block"
      :class="[
        CSSClasses,
        {
          'textinput-block--has-error': !!errorMessage,
          upload: fileUpload && isDragging,
          'has-icon': icon || fileUpload,
        },
      ]"
    >
      <div class="textinput-block__label-holder" v-if="label">
        <label :for="name" class="textinput-block__label">{{ label }}</label>

        <template v-if="$slots.tooltip">
          <div class="textinput-block__label-icon">
            <Tooltip>
              <FieldInfoIcon />

              <template #content><slot name="tooltip" /></template>
            </Tooltip>
          </div>
        </template>
      </div>

      <div class="textinput-block__input-holder">
        <div class="radio-controls">
          <div
            class="radio-controls__input-holder textinput-block__input"
            @dragover="onDragOver"
            @dragenter="onDragEnter"
            @dragleave="onDragLeave"
            @drop="onDrop"
          >
            <input
              ref="input"
              :id="name"
              :type="props.type"
              :placeholder="placeholder"
              :disabled="disabled"
              v-bind="field"
              v-model="modelValue"
            />

            <!-- Text Input to Display and Update Color Value -->
            <input
              v-if="props.type === 'color'"
              type="text"
              :value="modelValue"
              @input="updateColor"
              class="color-text-input"
              placeholder="Choose color"
            />

            <div v-if="fileUpload" class="image-holder" @click.stop.prevent="onClickUpload">
              <input
                type="file"
                accept="image/*"
                class="file-upload"
                @change="onFileChange"
                ref="uploadInput"
              />
              <inline-svg :src="UploadIcon" class="icon" />
            </div>
            <div v-else-if="icon" class="image-holder">
              <ImageMedia :filename="icon" class="icon" />
            </div>
            <slot v-else-if="reveal" name="reveal" />
          </div>
        </div>
        <slot name="password-strength-indicator" />

        <slot />
      </div>

      <div v-if="errorMessage && !$slots['password-strength-indicator']" class="error-message">
        {{ errorMessage }}
      </div>

      <div v-if="subtext" class="textinput-block__subtext">
        {{ subtext }}
      </div>
    </div>
  </Field>
</template>

<style scoped>
.radio-controls__input-holder {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  height: calc(var(--space) * 4.2);
  input:first-child {
    flex-grow: 1;
    text-overflow: ellipsis;
  }

  #logoBackgroundColor, #logo_background_color {
    flex-grow: unset;
    width: 1.5rem;
  }
}

.image-holder {
  .image-media,
  svg {
    width: 30px;
    /* transform: translate(0, 4px); */
    background-color: transparent;
    padding: 10px 5px 5px 5px;
    cursor: pointer;
  }
}

.upload {
  label {
    color: #d375ff;
  }
  svg {
    stroke: #d375ff;
  }
  .radio-controls__input-holder {
    border: 2px dashed #d375ff;
  }
}

input.file-upload {
  display: none;
}
</style>
