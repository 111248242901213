export function useDetectUserOS() {
  const isIOS = ref(false);
  const isAndroid = ref(false);

  function detectUserOS() {
    const { userAgent } = navigator;

    if (
      userAgent.indexOf('Win') !== -1 ||
      userAgent.indexOf('X11') !== -1 ||
      userAgent.indexOf('Linux') !== -1
    ) {
      isAndroid.value = true;
    }
    if (userAgent.indexOf('Mac') !== -1) {
      isIOS.value = true;
    }
  }

  detectUserOS();

  return { isIOS, isAndroid };
}
