<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    show: boolean;
    message?: string;
    type: 'success' | 'info' | 'error';
  }>(),
  {
    message: '',
    type: 'info',
  },
);

const typeDisplay = computed(() => {
  switch (props.type) {
    case 'success':
      return 'Success!';
    case 'error':
      return 'Error';
    default:
      return 'Info';
  }
});
</script>

<template>
  <transition name="fade">
    <div v-if="show" class="toast" :class="type" @click="$emit('hide')">
      <h3 class="toast-left">
        {{ typeDisplay }}
      </h3>
      <p class="toast-right">
        <b>{{ message }}</b>
      </p>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.toast {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: calc(100% - 24px);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 12px;
  border-radius: 18px;
  color: #fff;
  opacity: 0.95;
  cursor: pointer;
  backdrop-filter: blur(10px);
}
.toast-right {
  flex: 1;
  text-align: right;
  font-size: 16px;
}

.toast.success {
  background: transparentize(color(action, secondary), 0.1);
}

.toast.error {
  background: transparentize(color(bad, primary), 0.1);
}

.toast.info {
  background: transparentize(color(grey, medium), 0.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
