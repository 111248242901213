export function useDetectUserBrowser() {
  const browser = ref('');

  // Note: User agent string for Chrome, Edge, and Opera on macOS includes the word "Safari"
  function detectUserBrowser() {
    const { userAgent } = navigator;

    switch (true) {
      case userAgent.indexOf('Edg') !== -1:
        browser.value = 'Edge';
        break;
      case userAgent.indexOf('OPR') !== -1 || userAgent.indexOf('Opera') !== -1:
        browser.value = 'Opera';
        break;
      case userAgent.indexOf('Chrome') !== -1 && userAgent.indexOf('Edg') === -1 && userAgent.indexOf('OPR') === -1:
        browser.value = 'Chrome';
        break;
      case userAgent.indexOf('Firefox') !== -1:
        browser.value = 'Firefox';
        break;
      case userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1 && userAgent.indexOf('Edg') === -1 && userAgent.indexOf('OPR') === -1:
        browser.value = 'Safari';
        break;
      case userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1:
        browser.value = 'Internet Explorer';
        break;
      default:
        browser.value = 'Unknown';
    }
  }

  detectUserBrowser();

  return { browser };
}