<script lang="ts" setup>
import { ClovyrCodeLogo } from '@clovyr/bed';
import FediLogo from '@clovyr/bed/assets/images/fedimint.png';

import { useClovyrCodeHead } from '@/composables/useClovyrCodeHead';

import FediSocialImage from '../../../assets/images/fedimint-social.webp';

import InstantCode from './InstantCode.vue';

defineProps<{ instantLaunchApp: string }>();

useClovyrCodeHead('Fedimint', FediSocialImage);
</script>
<template>
  <InstantCode :instant-launch-app="instantLaunchApp" hide-code-desc>
    <div class="logo">
      <ImageMedia :filename="FediLogo" />
      <div class="plus">&nbsp;&nbsp;+&nbsp;&nbsp;</div>
      <ClovyrCodeLogo />
    </div>
    <p>
      Fedimint + Clovyr Code is a complete, one-click developer environment, so you can start
      building for the billions now.
    </p>
  </InstantCode>
</template>
