import { defineStore } from 'pinia';
import mitt, { type Emitter } from 'mitt';

import type { UserSubscription, UserSubscriptionItem } from '@clovyr/pollen';

import type { LoginProps } from '@/views/Settings/AuthForms/LoginForm.vue';
import type { SignupProps } from '@/views/Settings/AuthForms/SignupForm.vue';
import type { UnlockProps } from '@/views/Settings/AuthForms/Unlock.vue';
import type { UnlockSecretProps } from '@/views/Settings/AuthForms/UnlockSecret.vue';
import type { RecoveryKitDownloadProps } from '@/views/Settings/RecoveryKitDownload.vue';
import type { ShowAuthForm } from '@/views/Settings/types';

export type ShowPaymentModalEvent = {
  id: string;
  name: string;
  price?: number;
  sub: UserSubscription;
  subItem: UserSubscriptionItem;
};

type ShowLogin = {
  form?: ShowAuthForm.Login;
  /**
   * If user cancels log in, redirect to home page.
   */
  redirOnCancel?: boolean;
  opts?: LoginProps;
};

type ShowSignup = {
  form: ShowAuthForm.Signup;
  /**
   * If user cancels signup, redirect to home page.
   */
  redirOnCancel?: boolean;
  opts?: SignupProps;
};

// type ShowLockedAccount = {
//   form: ShowAuthForm.LockedAccount;
//   opts?: undefined;
// };

type ShowNostrEmail = {
  form: ShowAuthForm.NostrEmail;
  opts?: undefined;
};

type ShowRecoveryKitDownload = {
  form: ShowAuthForm.RecoveryKitDownload;
  opts: RecoveryKitDownloadProps;
};

type ShowUnlockSecret = {
  form: ShowAuthForm.SecretKeyForm;
  /**
   * If user cancels unlock, redirect to home page.
   */
  redirOnCancel?: boolean;
  opts?: UnlockSecretProps;
};

type ShowLocked = {
  form: ShowAuthForm.Locked;
  /**
   * If user cancels unlock, redirect to home page.
   */
  redirOnCancel?: boolean;
  opts?: UnlockProps;
};

type ShowResendVerification = {
  form: ShowAuthForm.ResendVerification;
  opts?: undefined;
};

type ShowVerificationComplete = {
  form: ShowAuthForm.VerificationComplete;
  opts?: undefined;
};

export type ShowLoginEvent =
  | ShowLogin
  | ShowSignup
  | ShowNostrEmail
  | ShowRecoveryKitDownload
  | ShowUnlockSecret
  | ShowLocked
  | ShowResendVerification
  | ShowVerificationComplete;
// | ShowLockedAccount

export type CaptchaStatus = { success: false } | { success: true; token: string };

type Events = {
  'drawer:close': void;
  'app:update': { appID: string };
  'captcha:complete': CaptchaStatus;
  'modal:login:show': ShowLoginEvent | undefined;
  'modal:login:close': void;
  'modal:login:force_close': void;
  'modal:unload:show': void;
  'modal:payment:show': ShowPaymentModalEvent;
  'toast:show': {
    message?: string;
    type: 'success' | 'info' | 'error';
    duration?: number;
    data?: object;
  };
};

/**
 * Simple store for using a shared global event bus for when we need to send events async across the
 * app without a simple parent/child component relationship.
 */
export const useEventBus = defineStore('event_bus', () => {
  const eventBus: Emitter<Events> = mitt();
  return {
    eventBus,
  };
});
