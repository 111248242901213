<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { RouteLocationRaw } from 'vue-router';

import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';

import CloseIcon from '@/components/icons/CloseIcon.vue';
import ClovyrLogoSmall from '@/components/icons/ClovyrLogoSmall.vue';
import useBodyScrollLock from '@/composables/useBodyScrollLock';
import { useMediaQueryStore } from '@/stores/media_query_store';

const router = useRouter();
const route = useRoute();
const isAppLauncher: boolean = route?.name?.toString().toLowerCase() === 'applauncher';

export type ModalTheme = 'base' | 'spinner' | 'transparent' | 'transparent-purple' | '';
export type ModalSize = 'wide' | 'medium' | 'narrow' | 'tight' | 'transparent-wide' | '';

const props = withDefaults(
  defineProps<{
    headingText?: string;
    onCloseRouteTo?: RouteLocationRaw;
    theme?: ModalTheme;
    size?: ModalSize;
    hasIcon?: boolean;
    hideClose?: boolean; // hides close icon in top right corner

    /**
     * When true, maximize the modal display so it takes up the entire screen on mobile (appears as
     * a full-screen overlay).
     */
    maxOnMobile?: boolean;
    stickyFooter?: boolean;
    show?: boolean;
  }>(),
  {
    headingText: '',
    onCloseRouteTo: '',
    theme: 'base',
    size: 'wide',
    hasIcon: false,
    hideClose: false,
    show: true,
  },
);

const { isMobile } = storeToRefs(useMediaQueryStore());

const emit = defineEmits(['open', 'close']);

const handleClose = () => {
  emit('close');
  if (props.onCloseRouteTo) {
    void router.push(props.onCloseRouteTo);
  }
};

const maxMobile = computed(() => isMobile.value && props.maxOnMobile);
const stickyFooter = computed(() => isMobile.value && props.stickyFooter);

let enableScrolling: (() => void) | undefined;
const modal = ref<HTMLElement | null>(null);

function escapeKeyHandler(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    handleClose();
  }
}

onMounted(async () => {
  void nextTick(() => {
    emit('open');
  });
  if (modal.value && props.show) {
    ({ enableScrolling } = await useBodyScrollLock(modal.value as HTMLElement));
    document.addEventListener('keydown', escapeKeyHandler);
  }
});

onUnmounted(() => {
  document.removeEventListener('keydown', escapeKeyHandler);
  if (enableScrolling) {
    // ensure that scrolling is re-enabled when the modal is closed
    enableScrolling();
  }
});

const classNames = computed(() => [
  `modal--${props.size}`,
  `modal--${props.theme}`,
  maxMobile.value && 'modal--max-mobile',
  stickyFooter.value && 'modal--sticky-footer',
  props.hasIcon && 'modal--has-icon',
]);
</script>

<template>
  <Teleport to="body">
    <div
      v-show="show"
      class="modal"
      ref="modal"
      :class="classNames"
      @keydown.esc="handleClose"
      tabindex="0"
    >
      <div @click="handleClose" class="modal__backdrop" />
      <div class="modal__frame modal-content">
        <div class="modal__progress-bar">
          <slot name="progress-bar" />
        </div>

        <div class="modal__inner" :class="{ logo: hasIcon }">
          <div v-if="headingText" class="modal__heading">
            {{ headingText }}
          </div>

          <div class="modal__body modal-body">
            <slot name="body" />
          </div>

          <div v-if="hasIcon" class="modal__logo">
            <slot name="icon">
              <ClovyrLogoSmall />
            </slot>
          </div>
        </div>
        <div class="modal-controls" v-if="$slots['controls-left'] || $slots['controls-right']">
          <div v-if="$slots['controls-left']" class="modal-controls__left">
            <slot name="controls-left" />
          </div>
          <div v-if="$slots['controls-right']" class="modal-controls__right">
            <slot name="controls-right" />
          </div>
        </div>

        <div v-if="theme !== 'spinner' && !isAppLauncher" class="modal__close">
          <slot name="close-extra" />
          <Button v-if="!hideClose" :shape="ButtonShape.Circle" @click="handleClose">
            <CloseIcon />
          </Button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
/**
 * ! CANNOT target these styles with deep selectors due to the use of Teleport!
 *   Instead, add a theme or other style via props that alter the display.
 *   Use sparingly!
 */
.modal {
  --modal-horiz-padding: #{space(9.5)};
  --modal-top-padding: #{space(7)};
  --modal-controls-height: #{space(9)};

  $self: &;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;

  // reflow modal based on screen size
  @include media-breakpoint-up(xs) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include media-breakpoint-up(sm) {
    height: 100vh;
    overflow-x: unset;
    overflow-y: unset;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: color(black, translucent);

    @supports (backdrop-filter: none) {
      backdrop-filter: blur(24px);
    }
  }

  &__frame {
    @include borders-rounded;

    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      max-height: calc(100% - 2.5rem);
    }

    margin: space(2);
    background: linear-gradient(180deg, #302744 0%, #42365e 100%);
    position: relative;
    overflow: hidden; // needed for rounded corners

    @include media-breakpoint-up(lg) {
      max-width: 960px;
      margin-right: auto;
      margin-left: auto;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1320px;
    }
  }

  &__inner {
    @include borders-rounded;
    @include disable-scrollbars;

    position: relative;

    padding: 2rem 2rem 10rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem 2rem 2.5rem;
      overflow: auto;
    }

    @include media-breakpoint-up(md) {
      padding: 4rem 6rem 4rem;
    }
  }

  &__heading {
    @include text--h2;

    max-width: col-width(5);

    @include font-size(36);
  }

  &__body {
    margin-top: space(4);
  }

  .modal-controls {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--modal-controls-height);
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: color(grey);

    // added to fix wrapping of payment buttons on mobile but broke for others modals
    // comment until we have a better fix
    // @include media-breakpoint-up(xs) {
    //   height: unset;
    // }

    @include media-breakpoint-up(sm) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include media-breakpoint-up(md) {
      padding-left: var(--modal-horiz-padding);
      padding-right: var(--modal-horiz-padding);
    }

    @include borders-rounded(bottom-left);
    @include borders-rounded(bottom-right);

    &__right {
      margin-left: auto;
    }
  }

  &__close {
    position: absolute;
    display: flex;
    top: var(--modal-top-padding);
    right: 3rem;
    z-index: 3;

    @include media-breakpoint-up(md) {
      right: var(--modal-horiz-padding);
    }
  }

  &__logo {
    position: absolute;
    top: 2.5rem;
    left: 2rem;
  }

  // sizes

  &--wide {
    .modal__inner {
      width: auto;
    }
  }
  &--medium {
    .modal__frame {
      max-width: 60rem;
    }
    .modal__inner {
      width: auto;
    }
  }

  &--has-icon {
    .modal__heading {
      margin-top: 2rem;
    }
  }

  &--tight {
    .modal__inner {
      @include media-breakpoint-up(md) {
        padding: 2.5rem 2.5rem 2.5rem;
      }

      .modal__body {
        margin-top: 0;
      }
    }

    .modal-controls {
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &--max-mobile {
    .modal__frame {
      @include media-breakpoint-down(sm) {
        border-radius: 0 !important;
        margin: 0;
        height: 100%;
        overflow-y: scroll;
      }
      .modal__inner {
        @include media-breakpoint-down(xs) {
          padding: 2.5rem 0.5rem 5.5rem;
        }
      }

      .modal-controls {
        @include media-breakpoint-down(sm) {
          border-radius: 0;
        }
      }
    }
  }
  &--sticky-footer {
    .modal-controls {
      @include media-breakpoint-down(sm) {
        position: fixed;
        bottom: 0;
        width: 100%;
      }
    }
  }

  &--narrow {
    align-items: center;
    display: flex;
    width: 100%;

    .modal__frame {
      margin-left: auto;
      margin-right: auto;

      .modal__inner {
        position: relative;
        padding: 2rem 2rem 2rem;

        &.logo {
          padding-top: 6rem;
        }

        .modal__heading {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.3;
          max-width: none;
        }

        .modal__body {
          margin-top: 1.25rem;
          color: color(grey, secondary);
        }
      }

      .modal__close {
        top: 0.75rem;
        right: 0.75rem;

        .button--circle {
          width: 1.375rem;
          height: 1.375rem;
          min-height: 0;
          padding: 0.2rem;

          svg {
            width: calc(var(--button-icon-size) / 2);
            height: calc(var(--button-icon-size) / 2);
            stroke: color(grey, secondary);
          }

          &:hover {
            /* stylelint-disable-next-line max-nesting-depth */
            svg {
              stroke: color(black, primary);
            }
          }
        }
      }

      .modal-controls {
        position: relative;
        column-gap: 1rem;
        padding: 0 2rem;
        background-color: transparent;

        .modal-controls__left,
        .modal-controls__right {
          .button {
            width: 100%;
          }
        }
      }
    }
  }

  // themes

  &--transparent {
    .modal__frame {
      background: linear-gradient(180deg, rgba(65 59 79 / 68%) 0%, #413b4f 91.78%);
      border-radius: 1.125rem;
      box-shadow: 0 0 20px rgba(34 26 51 / 40%);
    }
  }
  &--transparent-purple {
    .modal__frame {
      opacity: 0.8;
      border-radius: 18px;
      background: var(--cardGrad-3-Opaque, linear-gradient(180deg, #302744 0%, #42365e 100%));
    }
  }

  &--transparent-wide {
    @extend .modal--narrow;

    .modal__frame {
      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: col-width(12);
      }
    }
  }

  &--spinner {
    .modal__frame {
      width: 100px;
      background: none;
      box-shadow: none;
    }
  }
}
</style>
