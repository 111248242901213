<script lang="ts" setup>
import { ClovyrCodeLogo } from '@clovyr/bed';
import SproutIcon from '@clovyr/bed/components/icons/SproutIcon.vue';
import { isClovyrCode } from '@clovyr/pollen/garden';
import type { Manifest } from '@clovyr/pollen/manifest';

import CaptchaModal from '@/components/blocks/CaptchaModal.vue';
import FirefoxWarning from '@/components/blocks/FirefoxWarning.vue';
import Spinner from '@/components/elements/Spinner.vue';
import { claimClovyrCode } from '@/composables/claimClovyrCode';
import { useClovyrCodeHead } from '@/composables/useClovyrCodeHead';
import { useDetectUserBrowser } from '@/composables/useDetectUserBrowser';
import { useDeploymentStore } from '@/stores/deployment_store';
import { type CaptchaStatus, useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';

import InstanceList from '../../components/blocks/InstanceList.vue';
import Modal from '../../components/elements/Modal.vue';

const props = defineProps<{
  instantLaunchApp: string;
  manifest?: Manifest;
  hideCodeDesc?: boolean;
  interstitial?: boolean;
  headTitle?: string;
  headLogo?: string;
}>();

const pollenStore = usePollenStore();
const router = useRouter();
const deploymentStore = useDeploymentStore();
const { browser: userBrowser } = useDetectUserBrowser();

const showFirefoxModal = ref(false);
const showModal = ref(false);
const errorMessage = ref('');

const { eventBus } = useEventBus();
const captchaToken = ref('');
const showSpinner = ref(false);
const showCaptcha = ref(false);

onMounted(() => {
  void deploymentStore.loadDeployments();
});

const publisherID = computed(() => props.manifest?.metadata.publisher);

const isCode = computed(() => props.instantLaunchApp && isClovyrCode(props.instantLaunchApp));
const flavor = computed(() => {
  if (isCode.value) {
    return props.instantLaunchApp.substring(props.instantLaunchApp.indexOf('-') + 1);
  }
  return props.instantLaunchApp;
});

const app = computed(() => {
  if (props.manifest) {
    return props.manifest;
  }
  return pollenStore.garden.getAppByID(props.instantLaunchApp);
});

const logoURL = computed(() => {
  return app.value?.metadata.logoUrl;
});

async function claim() {
  if (!props.instantLaunchApp) {
    throw new Error('missing app to launch');
  }
  let appID = props.instantLaunchApp;
  if (props.manifest?.metadata.publisher && props.manifest.metadata.publisher !== 'clovyr') {
    appID = `${props.manifest.metadata.publisher}/${props.manifest.metadata.id}`;
  }

  showCaptcha.value = true;
  showSpinner.value = true;
  // TODO: store captcha complete status and check before showing captcha
  eventBus.on('captcha:complete', async (status: CaptchaStatus) => {
    showSpinner.value = false;
    if (!status.success) {
      // TODO: show err?
      return;
    }
    captchaToken.value = status.token;

    try {
      const newRoute = await claimClovyrCode(appID, appID, undefined, true);
      void router.push(newRoute);
    } catch (error) {
      if (error instanceof Error) {
        errorMessage.value = error.message;
        showModal.value = true;
      }
    }
  });
}

async function onClickLaunch() {
  if (userBrowser.value === 'Firefox') {
    showFirefoxModal.value = true;
    return;
  }
  void claim();
}

const launchLabel = computed(() => {
  if (props.interstitial) {
    // TODO: placeholder. update when we have a design for this page.
    return 'Launch now';
  }
  return 'Launch now';
});

async function onClickContinueAnyway() {
  showFirefoxModal.value = false;
  void claim();
}

if (isCode.value) {
  useClovyrCodeHead(props.headTitle || flavor.value, props.headLogo);
}
</script>
<template>
  <div class="instant">
    <div class="inner">
      <slot>
        <!-- default slot -->
        <div>
          <div class="logo">
            <template v-if="logoURL">
              <ImageMedia :filename="logoURL" />
              <div class="plus">&nbsp;&nbsp;+&nbsp;&nbsp;</div>
            </template>
            <ClovyrCodeLogo />
          </div>
        </div>
      </slot>

      <template v-if="!hideCodeDesc">
        <h2>Launch {{ flavor }}</h2>

        <p v-if="isCode && app?.metadata.description">{{ app.metadata.description }}</p>

        <p v-if="isCode">
          Clovyr Code is a full-featured VSCode, accessible via the browser, with a real terminal
          and command-line tools, running on a private VPS accessible only to you. Fast and
          customizable, without compromise.
        </p>
      </template>

      <div class="cta">
        <Button :label="launchLabel" reversed class="launch" @click="onClickLaunch">
          <Spinner v-if="showSpinner" theme="icon" />
          <SproutIcon v-else class="sprout" />
        </Button>
        <Button label="Learn more" link="https://clovyr.io/code" class="learn" translucent />
      </div>

      <div class="instance-list">
        <InstanceList :publisherID="publisherID" :appID="instantLaunchApp" />
      </div>
    </div>
  </div>

  <Modal v-if="showModal" @close="showModal = false">
    <template v-slot:body>
      <div class="instance-error">
        <h4>{{ errorMessage }}</h4>
      </div>
    </template>
  </Modal>

  <FirefoxWarning
    v-if="showFirefoxModal"
    @close="showFirefoxModal = false"
    @continue="onClickContinueAnyway"
  />

  <CaptchaModal v-if="showCaptcha" @close="showCaptcha = false" />
</template>

<style lang="scss" scoped>
.instant {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up(md) {
    align-items: center;
  }

  img {
    width: 246px;
  }

  p {
    @include text--color-grey-light;

    margin-top: 1em;
  }

  .inner {
    .logo,
    :slotted(.logo) {
      div {
        height: 50px;
        display: inline-block;
        img {
          min-width: 200px;
          max-width: 290px;
          display: inline-block;
        }
      }
      .plus {
        font-size: 3em;
        color: #e0dde4;
        line-height: 50px;
        height: 50px;
        vertical-align: top;
      }
      svg {
        max-width: 290px;
        display: inline-block;
        margin-bottom: 3px;
      }
    }

    padding: 4em;
    width: 760px;

    .code {
      h2 {
        margin-top: 40px;
      }
    }

    p,
    :slotted(p) {
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  .cta {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1.5em;
    height: 45px;

    .learn {
      :deep(.button__label) {
        color: white;
        text-transform: none;
      }
    }

    .launch {
      :deep(.button__label) {
        text-transform: none;
      }

      .sprout {
        margin-left: 0.5rem;
      }
    }
  }

  .instance-list {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin-top: 2.5em;
  }
}
</style>
