<script setup lang="ts">
const { theme = 'base' } = defineProps<{ theme?: 'base' | 'icon' | 'none' }>();

const emit = defineEmits(['onStop']);

onUnmounted(() => {
  emit('onStop');
});

const classNames = computed(() => [theme && `spinner-${theme}`]);
</script>

<template>
  <div :class="classNames" class="spinner-wrapper">
    <div class="spinner"></div>
  </div>
</template>

<style scoped lang="scss">
.spinner-wrapper {
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  // base theme adds a backdrop (for full screen spinner)
  &.spinner-base {
    background-color: rgba(0, 0, 0, 50%);
    backdrop-filter: blur(0.375rem);
  }

  // icon theme to fit inside a button
  &.spinner-icon {
    position: unset;
    display: unset;
    .spinner {
      --spinner-width: var(--button-icon-size);
      --spinner-height: var(--button-icon-size);
      --spinner-border-width: 5px;
      margin: 0;
      margin-left: 0.5rem;
    }
  }

  .spinner {
    --spinner-width: 5rem;
    --spinner-height: 5rem;
    $spinner-base-border-color: transparentize(color(white), 0.2);
    $spinner-main-border-color: color(grey, primary);
    --spinner-border-width: 0.8rem;

    animation: spinner 1.1s infinite linear;
    border-radius: 50%;
    margin: 60px auto;
    position: relative;
    border: var(--spinner-border-width) solid $spinner-base-border-color;
    border-left: var(--spinner-border-width) solid $spinner-main-border-color;
    transform: translateZ(0);
    width: var(--spinner-width);
    height: var(--spinner-height);

    &::after {
      border-radius: 50%;
      width: var(--spinner-width);
      height: var(--spinner-height);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
