<script setup lang="ts">
import Button from '@clovyr/bed/components/controls/Button.vue';
import languages from '@clovyr/pollen/fixtures/languages';
import type { Language } from '@clovyr/pollen/types';

import Spinner from '@/components/elements/Spinner.vue';
import { claimClovyrCode } from '@/composables/claimClovyrCode';
import { useDetectUserBrowser } from '@/composables/useDetectUserBrowser';
import { type CaptchaStatus, useEventBus } from '@/stores/event_bus';

import Modal from '../elements/Modal.vue';

import FirefoxWarning from './FirefoxWarning.vue';

const props = defineProps<{ err?: string }>();
const route = useRoute();
const router = useRouter();
const showModal = ref(false);
const showFirefoxModal = ref(false);
const errorMessage = ref('');

const emit = defineEmits(['show-captcha']);
const { eventBus } = useEventBus();

const selectedLang = ref<Language | null>(null);
const captchaToken = ref('');
const showSpinner = ref(false);

const { browser: userBrowser } = useDetectUserBrowser();

onMounted(() => {
  if (props.err === 'unavailable') {
    errorMessage.value = 'No instance available. Please try again in a few minutes';
    // edit url so that reloading this page doesn't show the modal again
    window.history.replaceState({}, '', route.path);
  }
  if (errorMessage.value) {
    showModal.value = true;
  }
});

async function claimLanguage(lang: Language) {
  showSpinner.value = true;
  // TODO: store captcha complete status and check before showing captcha
  emit('show-captcha');
  eventBus.on('captcha:complete', async (status: CaptchaStatus) => {
    showSpinner.value = false;
    if (!status.success) {
      // TODO: show err?
      return;
    }
    captchaToken.value = status.token;

    try {
      // TODO: check for idle instance state and update UI if it is
      const newRoute = await claimClovyrCode(
        'clovyr-code',
        `code-${lang.name.toLowerCase()}`,
        undefined,
        true,
        status.token,
      );
      void router.push(newRoute);
      showFirefoxModal.value = false;
    } catch (error) {
      if (error instanceof Error) {
        errorMessage.value = error.message;
        eventBus.emit('toast:show', { message: error.message, type: 'error', duration: 5000 });
        // showModal.value = true;
      }
    }
  });
}

async function onClickLanguage(lang: Language) {
  selectedLang.value = lang;
  if (userBrowser.value === 'Firefox') {
    showFirefoxModal.value = true;
    return;
  }
  void claimLanguage(lang);
}

async function onClickContinueAnyway() {
  showFirefoxModal.value = false;
  void claimLanguage(selectedLang.value!);
}
</script>

<template>
  <div class="app-list">
    <Button
      v-for="lang in languages"
      :key="lang.name"
      :label="lang.name"
      translucent
      :disabled="showSpinner"
      @click="onClickLanguage(lang)"
    >
      <Spinner v-if="showSpinner && lang.name === selectedLang?.name" theme="icon"></Spinner>
      <img v-else :src="`https://cstatic.app/code/img/${lang.icon}`" class="lang-icon" />
    </Button>

    <Modal v-if="showModal" @close="showModal = false">
      <template v-slot:body>
        <div class="instance-error">
          <h4>{{ errorMessage }}</h4>
        </div>
      </template>
    </Modal>

    <FirefoxWarning
      v-if="showFirefoxModal"
      @close="showFirefoxModal = false"
      @continue="onClickContinueAnyway"
    />
  </div>
</template>

<style scoped lang="scss">
.app-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;

  :deep(.button__label) {
    text-transform: none;
  }

  :deep(.modal__inner) {
    width: 500px;
    height: 150px;
    padding: 50px;
  }

  :deep(.modal__close) {
    top: 25px;
    right: 25px;
  }

  :deep(.modal__body) {
    margin-top: space(1);
  }

  .lang-icon {
    margin-left: 0.5rem;
  }
}
</style>
